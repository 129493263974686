import React from "react";
import { graphql, Link } from "gatsby";

import MarkdownStyle from "./markdown.module.css";
import Layout from "../components/layout";
import SEO from "../components/seo"


// TODO: add application form

export default function careerPage({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <SEO title={frontmatter.title}/>
      <div className="max-w-6xl mx-auto">
        <div className="px-6 md:px-16 xl:px-0">
          <div className="border border-blue-300 rounded shadow-lg my-10 p-5">
            <div className="flex flex-row flex-wrap justify-between items-center">
              <div>
                <h1 className="font-bold text-3xl capitalize">{frontmatter.title}</h1>
                <div className="py-2 text-gray-600">
                  <p>Location: {frontmatter.location} </p>
                  <p>Deadline: {frontmatter.due_date} </p>
                </div>
              </div>
              <div className="py-5 md:py-0">
                <a
                  href={`mailto:recruitment@cmteleservices.com.np?subject=Application for ${frontmatter.title}`}
                  className="rounded px-5 py-3 bg-blue-800 text-blue-100 tracking-wider hover:bg-blue-700"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
          <div className="my-10 flex flex-wrap -mx-10">
            <div className="w-full lg:w-3/4 px-10">
              <div
                dangerouslySetInnerHTML={{ __html: html }}
                className={MarkdownStyle.markdown}
              />
              <div className="my-20">
                <a
                  href={`mailto:recruitment@cmteleservices.com.np?subject=Application for ${frontmatter.title}`}
                  className="rounded px-5 py-3 bg-blue-800 text-blue-100 tracking-wider hover:bg-blue-700"
                >
                  Apply Now
                </a>
                <Link
                  to="/careers"
                  className="mx-3 rounded px-4 py-3 border border-transparent hover:border-blue-800 hover:shadow-md"
                >
                  &#8592; <spanc className="underline">Go Back</spanc>
                </Link>
              </div>
            </div>
            <div className="w-full lg:w-1/4 px-10">
              <div className="border border-blue-400 shadow-lg rounded my-10 p-5">
                <h3 className="text-blue-800 font-bold">Need Help?</h3>
                <div className="text-sm">
                  <p className="py-2">
                    Not sure exactly what you are looking for or just want
                    clarification? We'd be happy to chat with you and clear
                    things up for you. Anytime!
                  </p>
                  <p className="font-medium text-blue-800 py-2">Email Us</p>
                  <p className="break-words">
                    recruitment@cmteleservices.com.np
                  </p>
                </div>
              </div>
              <div className="border border-blue-400 shadow-lg rounded p-5">
                <h3 className="text-blue-800 font-bold">
                  Don't see a job for you?
                </h3>
                <p className="text-sm pt-2">
                  Do you feel like you belong working with CM Teleservices, but
                  we just don't have your dream job posted? No problem, just
                  reach out. Let us know.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        due_date(formatString: "MMMM DD, YYYY")
        title
        location
      }
      html
    }
  }
`;
